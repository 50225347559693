import React, { useEffect } from 'react';
import styled from 'styled-components';

const StyledWatermark = styled.div`
  width: 25%;
  height: auto;
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  z-index: 99;
  pointer-events: none;

  img {
    width: 100% !important;
    height: auto !important;
    display: block;
    margin: 0;
    padding: 0;
    border-radius: 0px !important;
    max-width: none !important;
    min-width: auto;
    min-height: auto;
    position: relative !important;
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: #4f78e8;
    color: white;
    padding: 3px;
  }

  &.watermark-lt {
    top: 0;
    height: auto;
    display: block;
    position: absolute;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 99;
    pointer-events: none;
  }

  &.watermark-fullwidth {
    width: auto;
  }

  &.watermark-covert {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.watermark-lt {
    top: 0;
    left: 0;
  }

  &.watermark-rt {
    top: 0;
    right: 0;
  }

  &.watermark-lb {
    left: 0px;
    bottom: 0px;
  }

  &.watermark-rb {
    bottom: 0;
    right: 0;

    img {
      position: absolute !important;
      bottom: 0;
    }
  }

  &.watermark-lb {
    bottom: 0;
    left: 0;
  }

  &.watermark-covert img {
    width: 100% !important;
    height: 100% !important;
    display: block;
  }

  &.watermark-homepage-covert {
    background-color: transparent;
    width: 100%;
    height: 135px;

    @media (max-width: 768px) {
      width: calc(100% - 10px);
      height: 130px !important;
    }
  }

  &.watermark-homepage-covert img {
    background-color: transparent !important;
  }

  &.watermark-homepage {
    background-color: transparent;
    width: 30%;
  }

  &.watermark-homepage img {
    background-color: transparent !important;
  }

  &.watermark-homepage-callout {
    width: auto;
    height: auto;
    max-width: 70%;
  }

  &.watermark-homepage-callout-full {
    width: auto;
    height: auto;
    max-width: 100%;
  }

  &.watermark-suggest {
    height: 100%;
  }

  &.watermark-menu-covert {
    height: 100%;

    @media (max-width: 768px) {
      height: 100% !important;
    }
  }

  &.watermark-menu {
    width: 30%;
    height: 30%;
  }

  &.watermark-menu-callout {
    bottom: 0px;
    width: auto;
    height: auto;
    max-width: 70%;
  }

  &.watermark-menu-callout-full {
    bottom: 0px;
    width: auto;
    height: auto;
    max-width: 100%;
  }

  &.watermark-pdp {
    width: 25%;
    height: auto;
  }

  &.watermark-pdp-callout {
    font-size: 1.33em;
  }
`;

const Watermark = ({ product }) => {
  return (
    <>
      {product?.watermark?.coverVisible &&
        product?.watermark?.coverImageUrl && (
          <StyledWatermark className="watermark-covert">
            <img
              alt={product?.brand ? product?.brand : product?.productName}
              src={product?.watermark.coverImageUrl}
            />
          </StyledWatermark>
        )}
      {product?.watermark?.brandVisible &&
        product?.watermark?.brandImageUrl && (
          <StyledWatermark className="watermark-lt watermark-homepage watermark-menu">
            <img
              alt={product?.brand ? product?.brand : product?.productName}
              src={product?.watermark.brandImageUrl}
            />
          </StyledWatermark>
        )}
      {product?.watermark?.discountVisible &&
        product?.watermark?.discountImageUrl && (
          <StyledWatermark className="watermark-rt watermark-homepage watermark-menu">
            <img
              alt={product?.watermark.discountValue}
              src={product?.watermark.discountImageUrl}
            />
          </StyledWatermark>
        )}
      {/* {product?.watermark?.calloutMsgVisible &&
        product?.productPromotions.length > 0 && (
          <StyledWatermark
            className={`watermark-fullwidth watermark-lb ${
              product?.watermark.markVisible
                ? 'watermark-menu-callout'
                : 'watermark-menu-callout-full'
            }`}
          >
            <p>{product?.productPromotions[0].calloutMsg}</p>
          </StyledWatermark>
        )} */}

      {product?.watermark?.calloutMsgVisible &&
        product?.watermark?.calloutMsgImageUrl && (
          <StyledWatermark
            className={`watermark watermark-fullwidth watermark-lb ${
              product?.watermark?.calloutMsgVisible
                ? 'watermark-menu-callout'
                : 'watermark-menu-callout-full'
            }`}
          >
            <img
              alt={product?.watermark?.productName}
              src={product?.watermark?.calloutMsgImageUrl}
            />
          </StyledWatermark>
        )}
      {product?.watermark?.markVisible && product?.watermark?.markImageUrl && (
        <StyledWatermark className="watermark-rb watermark-homepage watermark-menu">
          <img
            alt={product?.brand ? product?.brand : product?.productName}
            src={product?.watermark.markImageUrl}
          />
        </StyledWatermark>
      )}
    </>
  );
};

export default Watermark;
