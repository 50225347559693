import React, { useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import { md } from 'utils/breakpoints';

import IconAdd from 'images/add.svg';
import IconSubtract from 'images/subtract.svg';
import IconDeleteItem from 'images/delete-item.svg';

const Container = styled.div`
  display: inline-block;
  position: absolute;
  z-index: 100;
`;

const CounterBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  cursor: pointer;
  user-select: none;
  transition: all 700ms;
  position: absolute;

  img {
    width: 11px;
    height: 11px;
    position: relative;
    left: 0;
    filter: drop-shadow(#ffffff 9999px 0);
  }

  &:hover {
    background-color: #5fd2da;

    @media (max-width: ${md}) {
      background-color: transparent;
    }

    img {
      left: -9999px;

      @media (max-width: ${md}) {
        left: 0;
      }
    }
  }
`;

const AddBtn = styled(CounterBtn)`
  right: -2px;
  opacity: 1;
`;

const SubtractBtn = styled(CounterBtn)`
  left: -2px;
  opacity: 0;
`;

const CountText = styled.span`
  color: #3b3516;
  font-size: 14px;
  font-weight: 400;
  opacity: 0;
  transition: all 700ms;
`;

const CounterWrapper = styled.div`
  position: relative;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 30px;
  width: 28px;
  height: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 700ms;

  ${(props) =>
    props.expand &&
    css`
      border: 2px solid #e3e3e3;
      width: 84px;

      ${CountText} {
        opacity: 1;
      }

      ${SubtractBtn} {
        left: -2px;
        opacity: 1;
      }
    `}
`;

const CountDisplayBtn = styled.div`
  position: absolute;
  right: -2px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background-color: #f9e05f;
  border-radius: 50%;
  color: #3b3516;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: all 700ms;
`;

const DELAY_TIME = 2000;

const CounterProduct = (props) => {
  const { className, count, onChange, type = 'normal', isAdd = false } = props;
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const [expand, setExpand] = useState(false);
  const [additionCount, setAdditionCount] = useState(0);

  const collapse = useCallback(
    _.debounce(() => {
      setExpand(false);
    }, DELAY_TIME),
    []
  );

  const handleAdd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (type !== 'normal' && count > 0) {
      alert('加價購商品僅限購買一件');
      return;
    }
    onChange(count + 1);
    setExpand(true);
  };

  const handleSubtract = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (count === 0) return;
    onChange(count - 1);
  };

  const handleClickDisplayBtn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpand(true);
  };

  useEffect(() => {
    collapse();
  }, [count, expand]);

  return (
    <Container className={className}>
      <CounterWrapper expand={expand}>
        <SubtractBtn onClick={handleSubtract}>
          {isIOS ? (
            <object
              data={count <= 1 ? IconDeleteItem : IconSubtract}
              type="image/svg+xml"
            ></object>
          ) : (
            <img src={count <= 1 ? IconDeleteItem : IconSubtract} />
          )}
        </SubtractBtn>
        <CountText>{count}</CountText>
        {count > 0 && !expand ? (
          <CountDisplayBtn onClick={handleClickDisplayBtn}>
            {count}
          </CountDisplayBtn>
        ) : (
          <AddBtn onClick={handleAdd}>
            {isIOS ? (
              <object data={IconAdd} type="image/svg+xml"></object>
            ) : (
              <img src={IconAdd} />
            )}
          </AddBtn>
        )}
      </CounterWrapper>
    </Container>
  );
};

export default CounterProduct;
