import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import sha256 from 'crypto-js/sha256';

import store, { actions, getters } from 'redux/store';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isDesktop, isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import { navigate } from 'gatsby';
import { SEO_API_DOMAIN } from 'utils/constants/seo.js';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import Breadcrumb from 'components/UI/Breadcrumb';
import Pagination from 'components/UI/Pagination';
import Footer from 'components/UI/Footer';
import CardProduct from 'components/UI/Card/CardProduct';
import MetaSearch from 'components/Seo/Meta/MetaSearch';

import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import { getDispatchData } from 'utils/_dispatch.js';
import { isAuthenticated } from 'utils/_auth.js';
import { setSelectItem, setViewItemListOnSearch } from 'utils/_gtag.js';

import Cookies from 'js-cookie';

import IconArrow from 'images/arrow.svg';

const mixinResultList = css`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 28px;

  @media (max-width: ${md}) {
    gap: 8px;
    margin-bottom: 0;
  }
`;

const mixinCardProduct = css`
  flex: 0 0 calc((100% - 4 * 12px) / 5);
  min-height: 291px;

  @media (max-width: ${md}) {
    flex: 0 0 calc((100% - 2 * 8px) / 3);
    min-height: 184px;
  }
`;

const Container = styled.div`
  width: 1080px;
  margin: ${(props) => `${props.headerHeight}px`} auto 0;

  @media (max-width: ${md}) {
    width: 100%;
  }

  .skeleton-search-wrapper {
    ${mixinResultList};

    .skeleton-search {
      ${mixinCardProduct};
    }
  }
`;

const StyledHeader = styled(Header)`
  .address-wrapper,
  .switch-delivery-wrapper {
    @media (max-width: ${md}) {
      display: none;
    }
  }

  .title {
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

const StyledPagination = styled(Pagination)`
  margin-bottom: 10px;
`;

const StyledCardProduct = styled(CardProduct)`
  ${mixinCardProduct};
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    margin-bottom: 5px;
    align-items: center;
  }
`;

const SearchText = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #333333;

  @media (max-width: ${md}) {
    font-size: 12px;
  }

  > span {
    font-size: 32px;
    font-weight: 700;

    @media (max-width: ${md}) {
      font-size: 18px;
    }
  }
`;

const ResultList = styled.div`
  ${mixinResultList};
`;

const SwitchList = styled.div`
  display: flex;
  gap: 0 12px;
`;

const SwitchBtn = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #3b3516;
  cursor: pointer;
  padding: 6px 4px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;

  @media (max-width: ${md}) {
    font-size: 14px;
  }

  ${(props) =>
    props.isActive &&
    css`
      font-weight: 500;
      border-bottom: 2px solid #5fd2da;
    `}
`;

const Section = styled.div`
  @media (max-width: ${md}) {
    background-color: #fff;
    padding: 9px 14px;
  }
`;

let currentPage = 1;
let total = 0;
let totalPage = 0;
const perPage = 25;
let arrProducts = [];

const SearchResult = ({ location, serverData }) => {
  console.log('serverData', serverData);
  const api = useAPI();
  const resultList = useRef();
  const loading = useLoading();
  const dispatchData = getDispatchData();
  const storeId = _.get(dispatchData, 'storeId', '');
  const PAGE_CACHE_KEY = 'searchResultPage';
  const searchParams = new URLSearchParams(location.search);
  const keyword = searchParams.get('q') ? searchParams.get('q') : '';
  if (isDesktop) {
    currentPage = searchParams.get('page')
      ? Number(searchParams.get('page'))
      : 1;
  }

  const localCacheData = useSelector((state) => getters.getLocalCache(state));
  const keepaliveData = useSelector((state) => getters.getKeepalive(state));
  const [domLoaded, setDomLoaded] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [results, setResults] = useState();
  const [hasMore, setHasMore] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [showNoData, setShowNoData] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    page: '1',
    per_page: '25',
    total: 0,
  });

  const fetchProductList = () => {
    let mobile = dispatchData?.mobile ?? '';
    setResults(undefined);
    api
      .searchProduct({
        keyword,
        currentPage,
        perPage,
        storeId,
        visitorId:
          mobile !== '' ? sha256(mobile).toString().toUpperCase() : 'anonymous',
      })
      .then((res) => {
        if (!res) return;
        setShowNoData(res.rows.length === 0 ? true : false);
        setResults(res.rows);
        setDataCount(res.total);
        setPageInfo({
          page: res.page,
          per_page: res.per_page,
          total: res.total,
        });

        // 將attribution token寫入cookie
        // TODO: api加入後打開
        Cookies.set('attributionToken', res.attributionToken, { expires: 1 });

        // GA event
        setViewItemListOnSearch(keyword, res, res.attributionToken);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const saveKeyword = (word) => {
    const searchJsonString = localStorage.getItem('search');
    if (searchJsonString !== null) {
      const searchJson = JSON.parse(searchJsonString);
      let isIn = false;
      for (const element of searchJson) {
        if (element === word) {
          isIn = true;
        }
      }
      if (!isIn) {
        searchJson.reverse().push(word);
        localStorage.setItem(
          'search',
          JSON.stringify(searchJson.reverse().slice(0, 5))
        );
      }
    } else {
      const searchs = [];
      searchs.push(word);
      localStorage.setItem('search', JSON.stringify(searchs));
    }
  };

  const handleChangeCount = (count, productId) => {
    const updatedProduct = results.map((product) => {
      if (productId === product.id) {
        return { ...product, qty: count };
      }
      return product;
    });
    setResults(updatedProduct);
  };

  const getInfo = (fromCache = false) => {
    api
      .getInfo({ passLocationInfo: 'true', passShippingInfo: 'true' })
      .then((res) => {
        store.dispatch(actions.setInfo(res));
        const newProds = [];

        arrProducts.forEach((p) => {
          let result = res.cart.productCount.find((infoProd) => {
            return p.id === infoProd.productNo;
          });
          const asProd = Object.assign({}, p);

          if (result !== undefined) {
            asProd.qty = result.count;
          } else {
            asProd.qty = 0;
          }
          newProds.push(asProd);
        });

        loading.dismiss();
        setShowPage(true);
        setResults(newProds);

        if (fromCache) {
          const offsetData = keepaliveData[`search-result-offset`];
          if (offsetData !== undefined && offsetData !== null) {
            window.scrollTo(0, offsetData.position);
          }
        }
      })
      .catch((error) => {
        loading.dismiss();
        alert(error);
      });
  };

  const doSearchResult = (word) => {
    if (arrProducts.length <= 0) {
      const resultsCache = localCacheData[`${PAGE_CACHE_KEY}-${word}`];
      if (resultsCache !== undefined && resultsCache !== null) {
        currentPage = resultsCache.currentPage;
        totalPage = resultsCache.totalPage;
        arrProducts = resultsCache.results;
        setHasMore(resultsCache.hasMore);
        getInfo(true);

        if (resultsCache.results.length === 0) {
          setShowNoData(true);
        } else {
          setShowNoData(false);
          setShowPage(true);
        }
        return;
      }
    }

    if (currentPage === 1 || currentPage <= totalPage) {
      setHasMore(true);
      /* word = word === undefined ? searchWord : word; */
      if (currentPage === 1) {
        setShowPage(false);
      }
      api
        .searchProduct({ keyword: word, currentPage, perPage, storeId })
        .then((res) => {
          if (!res) return;
          const rowArr = res.rows;
          arrProducts = [...arrProducts, ...rowArr];
          if (currentPage === 1) {
            if (rowArr.length === 0) {
              /* setSearchWord(word); */
              setShowNoData(true);
            } else {
              setShowNoData(false);
            }
          }

          // 將attribution token寫入cookie
          Cookies.set('attributionToken', res.attributionToken, { expires: 1 });

          setViewItemListOnSearch(word, res, res.attributionToken);

          setDataCount(res.total);
          totalPage = Number.parseInt(res.total / perPage);
          if (res.total % perPage !== 0) {
            totalPage++;
          }
          setPageInfo({
            page: res.page,
            per_page: res.per_page,
            total: res.total,
          });
          if (res.total === res.rows.length) {
            setHasMore(false);
          }
          if (isAuthenticated()) {
            getInfo();
          } else {
            loading.dismiss();
            setShowPage(true);
            setResults(arrProducts);
          }
          currentPage = currentPage + 1;
        })
        .catch((error) => {
          loading.dismiss();
          alert(error);
        });
    } else {
      setShowPage(true);
      setHasMore(false);
    }
  };

  /* const searchKeyWord = () => {
    const searchQuery = localStorage.getItem('searchQuery');
    if (searchQuery != null) {
      setSearchWord(searchQuery);
      doSearchResult(searchQuery);
    } else {
      setSearchWord(keyword);
      doSearchResult(keyword);
    }
  }; */

  const renderResultList = () => {
    return (
      <ResultList>
        {results?.map((product, index) => {
          const itemData = {
            id: product.id,
            storeId: storeId,
            img: product.image,
            price: product.promo_price,
            originPrice: product.price,
            name: product.title,
            count: product.qty,
            promotions: product.productPromotions,
          };
          return (
            <StyledCardProduct
              key={`StyledCardProduct-${index}`}
              goToPage={`/product?pid=${itemData.id}`}
              item={itemData}
              onChangeCount={(count, productId) => {
                handleChangeCount(count, productId);
              }}
              productData={product}
              onGo2Detail={(id) => {
                console.log('GTAG-search', keyword);
                // GTAG: 點選"搜尋結果頁"商品
                setSelectItem(
                  {
                    listId: product.id,
                    listName: keyword,
                  },
                  product
                );
              }}
            />
          );
        })}
      </ResultList>
    );
  };

  // 桌機版
  useEffect(() => {
    if (isDesktop) {
      saveKeyword(keyword);
      fetchProductList();
    }
  }, [keyword, currentPage]);

  // 手機版
  useEffect(() => {
    if (isMobile) {
      arrProducts = [];
      currentPage = 1;
      totalPage = 0;
      saveKeyword(keyword);
      doSearchResult(keyword);
      /* searchKeyWord(); */
    }
  }, [keyword]);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <>
      <MetaSearch serverData={serverData} />
      {domLoaded && (
        <Container headerHeight={headerHeight}>
          <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
            <StyledHeader
              title="搜尋結果"
              onRefresh={() => doSearchResult(keyword)}
            />
          </HeaderWrapper>
          <Section>
            <Breadcrumb
              items={[
                { title: <Link to="/">首頁</Link> },
                { title: '搜尋' },
                { title: keyword },
              ]}
            />
            {results && results.length > 0 && (
              <TitleBar>
                <SearchText>
                  <span>“{keyword}”</span>的 {dataCount} 筆結果
                </SearchText>
              </TitleBar>
            )}

            {isDesktop && results === undefined && (
              <Skeleton
                containerClassName="skeleton-search-wrapper"
                className="skeleton-search"
                count={30}
                inline
              />
            )}
            {isDesktop && results !== undefined && results.length !== 0 && (
              <>
                {renderResultList()}
                <StyledPagination
                  total={pageInfo.total}
                  pageSize={Number(pageInfo.per_page)}
                  current={Number(pageInfo.page)}
                  onPageChange={(page) => {
                    if (typeof window !== 'undefined') {
                      window.location = `/search?q=${keyword}&page=${page}`;
                    }
                  }}
                />
              </>
            )}

            {isMobile &&
              !showNoData &&
              (showPage && results.length > 0 ? (
                <InfiniteScroll
                  ref={resultList}
                  dataLength={results.length}
                  next={() => doSearchResult(keyword)}
                  hasMore={hasMore}
                  style={{ height: '100%', overflow: 'unset' }}
                  loader={<h6>資料載入中...請稍候</h6>}
                >
                  {renderResultList()}
                </InfiniteScroll>
              ) : (
                <Skeleton
                  containerClassName="skeleton-search-wrapper"
                  className="skeleton-search"
                  count={30}
                  inline
                />
              ))}

            {showNoData && (
              <div>
                抱歉沒有找到與 <strong>"{keyword}"</strong> 相關的產品
                <br />
                Currently, our search engine could only support Chinese.
                <br />
                Sorry for the inconvenience.
              </div>
            )}
          </Section>
        </Container>
      )}
      <Footer />
    </>
  );
};

export default SearchResult;

export async function getServerData(context) {
  try {
    const headers = context.headers;
    const keyword = context.query.q;
    const currentUrl = 'https://' + headers.get('host') + '?q=' + keyword;
    const host = headers.get('host');

    /* const [res1] = await Promise.all([
      fetch(
        `${SEO_API_DOMAIN}v1/sfcc/products/search?searchword=${keyword}&storeId=02H&page=1&per_page=24`
      ),
    ]);
    if (!res1.ok) {
      throw new Error(`Response failed`);
    }
    console.log('res1', res1);
    const search = await res1.json(); */

    const mergedData = {
      /* search, */
      keyword: keyword,
      useragent: headers.get('user-agent'),
      host: host,
      currentUrl: currentUrl,
    };

    return {
      props: await mergedData,
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}
