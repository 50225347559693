import React, { useCallback, useState, useRef } from 'react';
import store, { actions, getters } from 'redux/store';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import _ from 'lodash';

import CounterProduct from 'components/UI/Counter/CounterProduct';
import Auth from 'components/Auth';
import Watermark from 'components/UI/Watermark/CardProduct';

import { useAPI } from 'utils/api';
import { isAuthenticated } from 'utils/_auth.js';
import { md } from 'utils/breakpoints';
import { setAddToCart } from 'utils/_gtag.js';

import Cookies from 'js-cookie';

const Container = styled(Link)`
  display: inline-block;
  width: 206px;
  min-height: 291px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  padding: 18px 12px;
  cursor: pointer;

  @media (max-width: ${md}) {
    width: 110px;
    min-height: 184px;
    padding: 8px 5px;
  }
`;

const StyledCounterProduct = styled(CounterProduct)`
  position: absolute;
  right: 3px;
  bottom: 6px;

  @media (max-width: ${md}) {
    right: 7px;
    bottom: 0;
  }
`;

const ImgWrapper = styled.div`
  position: relative;
  margin: 0 auto 18px;
  width: 164px;
  height: 164px;

  @media (max-width: ${md}) {
    width: 100px;
    height: 100px;
    margin: 0 auto 8px;
  }
`;

const ItemImg = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
`;

const ItemPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`;

const ItemPrice = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    font-size: 16px;
  }

  > span {
    font-size: 12px;
    font-weight: 400;
  }
`;

const ItemOriginPrice = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #868686;
  text-decoration: line-through;

  @media (max-width: ${md}) {
    font-size: 12px;
  }
`;

const ItemName = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: ${md}) {
    font-size: 12px;
  }
`;

const PromotionLabelList = styled.div``;

const PromotionLabel = styled.div`
  display: inline-block;
  background-color: #fff4bc;
  border-radius: 30px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 400;
  color: #3b3516;
`;

const CardProduct = (props) => {
  const {
    className,
    item,
    productData,
    onChangeCount,
    onGo2Detail,
    boardInfo = undefined,
    goToPage = '',
    updateCartProductList,
    productType = '', //空字串:一般商品, 'addition':加價購
  } = props;
  const { img, price, originalPrice, name, count, id, storeId, promotions } =
    item;
  const api = useAPI();
  const countRef = useRef();

  const [doLogin, setDoLogin] = useState(false);

  const updateCart = (count) => {
    const params = {
      productId: id,
      quantity: count,
      currentStoreId: storeId,
      shouldGetInfo: true,
    };
    api
      .updateCart(params)
      .then((res) => {
        if (!res) {
          if (onChangeCount) onChangeCount(countRef.current, id);
          return;
        }
        countRef.current = count;
        store.dispatch(actions.setCartItemCount(res.totalqtyCount));
        if (updateCartProductList) updateCartProductList(res.productItems);
        // 從cookie中取得attributionToken
        const attributionToken = Cookies.get('attributionToken');
        // GTAG: 點選"主題商品類別"區加入購物車
        setAddToCart(res, id, productData, boardInfo, attributionToken);

        // ALVIN 2023/05/19 update redux cart & cart price
        //store.dispatch(actions.setCartList(res.productItems));
        //store.dispatch(actions.setCartPrice(res));
      })
      .catch((error) => console.log(error));
  };

  const handleChangeCount = (count) => {
    if (onChangeCount) onChangeCount(count, id);
    updateCart(count);
  };

  return (
    <Container
      className={className}
      to={goToPage}
      onClick={() => {
        if (onGo2Detail) onGo2Detail(id);
      }}
    >
      {doLogin && <Auth />}
      <ImgWrapper className="img-wrapper">
        {count !== undefined && (
          <StyledCounterProduct
            count={count}
            onChange={(count) => {
              isAuthenticated() ? handleChangeCount(count) : setDoLogin(true);
            }}
          />
        )}
        <ItemImg imageUrl={img} />
        <Watermark product={productData} />
      </ImgWrapper>
      <ItemPriceWrapper>
        <ItemPrice>
          <span>$</span>
          {price}
        </ItemPrice>
        {price < originalPrice && (
          <ItemOriginPrice>${originalPrice}</ItemOriginPrice>
        )}
      </ItemPriceWrapper>
      <PromotionLabelList>
        {promotions?.map((promotion, index) => {
          if (promotion.calloutMsg === '') return;
          return (
            <PromotionLabel key={`PromotionLabel-${index}`}>
              {promotion.calloutMsg}
            </PromotionLabel>
          );
        })}
      </PromotionLabelList>
      <ItemName>{name}</ItemName>
    </Container>
  );
};

export default CardProduct;
